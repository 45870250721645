import React from 'react';
import { Box } from '@amzn/awsui-components-react';

interface DisplayContentInCodeProps {
  details: any;
}
const DisplayContentInCode: React.FC<DisplayContentInCodeProps> = (details: any) => {
  const STAGE: any = 'prod';
  return (
    <>
      {STAGE === 'dev' && (
        <Box variant="code">
          <pre
            style={{
              background: '#f6f8fa',
              padding: '2rem'
            }}
          >
            {JSON.stringify(details, null, 2)}
          </pre>
        </Box>
      )}
    </>
  );
};

export default DisplayContentInCode;
