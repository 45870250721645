/* istanbul ignore file */
import KatalLogger, { Level } from '@amzn/katal-logger';
import { UserAuthContext } from './components/context/AppContextModels';

export let logger: KatalLogger;

export const configureTheLogger = (userAlias: string, userAuthData: UserAuthContext) => {
  const katalLoggerConfig = {
    url: 'https://logger.fintech.device.finance.amazon.dev/v1/log',
    logThreshold: Level.INFO,
    maxLogLineSize: 10000,
    logToConsole: true,
    context: {
      app: 'DasFinSuite-website',
      env: 'prod',
      userAlias: userAlias,
      userAuthData: userAuthData
    }
  };
  logger = new KatalLogger(katalLoggerConfig);
  logger.addErrorListener();
};
