import { GetParameterCommand, GetParameterCommandOutput, SSMClient } from '@aws-sdk/client-ssm';
import { LicenseManager } from 'ag-grid-enterprise';
import { getCredentialsFromAWSService } from 'src/components/context/AuthContextUtils';
import { logger } from 'src/logger';

const AgGridLicenseKeyParameterStoreKey = '/das-finsuite/fe/ag-grid-license';

export const ENV_CONSTANTS = Object.freeze({
  ENVIRONMENT_VARIABLES: {
    Region: 'us-west-2',
    Stage: 'prod',
    CloudFrontProtocol: 'https',
    CloudFrontDomain: 'd1ri611kznd746.cloudfront.net'
  }
});

export const S3_ENV_CONSTANTS = Object.freeze({
  ENVIRONMENT_VARIABLES: {
    Bucket: ENV_CONSTANTS.ENVIRONMENT_VARIABLES.Stage === 'dev' ? 'op-excellence-alpha' : 'op-excellence-prod',
    Prefix: 'oe-rpt-tables-data-export/'
  }
});

//Function to configure the Ag Grid enterprise license
export const configureAgGridLicense = async (): Promise<string> => {
  const licenseKey = await retrieveParameterFromSSM(AgGridLicenseKeyParameterStoreKey);
  if (licenseKey) {
    LicenseManager.setLicenseKey(licenseKey);
    logger.info('Ag-Grid License configured successfully!', { logType: 'configStatus' });
    return new Promise((resolve) => resolve('setLicenseKey_Success'));
  } else {
    logger.error('Ag-Grid License setup failed!', { logType: 'configStatus' });
    return new Promise((reject) => reject('setLicenseKey_Failed'));
  }
};

/**
 * Retrieves a SecureString value from AWS Systems Manager Parameter Store.
 * @param parameterName The name of the parameter in Parameter Store.
 * @returns The string value of the parameter.
 */
export const retrieveParameterFromSSM = async (parameterName: string): Promise<string> => {
  const REGION = ENV_CONSTANTS.ENVIRONMENT_VARIABLES.Region;
  const ssmClientConfig = { region: REGION, credentials: await getCredentialsFromAWSService() };
  const ssmClient = new SSMClient(ssmClientConfig);
  const command = new GetParameterCommand({ Name: parameterName, WithDecryption: true });
  const response: GetParameterCommandOutput = await ssmClient.send(command);
  if (!response.Parameter?.Value)
    logger.error(`Unable to load Ag-Grid License key from AWS Systems Manager Parameter Store with key ${parameterName}`);
  return response.Parameter?.Value || '';
};

//Function to retrieve the secret key based on environment
export function getSecretARNForAGGridLicenseKey(stage = ENV_CONSTANTS.ENVIRONMENT_VARIABLES.Stage): string {
  const devAndAlphaSecretKey = 'arn:aws:secretsmanager:us-west-2:850882930567:secret:ag-grid-license-key-alpha-Jhc2WQ';
  switch (stage) {
    case 'dev':
      return devAndAlphaSecretKey;
    case 'alpha':
      return devAndAlphaSecretKey;
    case 'beta':
      return 'arn:aws:secretsmanager:us-west-2:186446126748:secret:ag-grid-license-key-beta-rAvlUH';
    case 'prod':
      return 'arn:aws:secretsmanager:us-west-2:287997430343:secret:ag-grid-license-key-prod-3kGQIL';
    default:
      throw new Error('SecretARN is not configured');
  }
}
