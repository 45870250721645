import { GetSecretValueCommand, GetSecretValueResponse, SecretsManagerClient, SecretsManagerClientConfig } from '@aws-sdk/client-secrets-manager';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import { Auth } from 'aws-amplify';
import { BackendGraphQLApiConfig, SecretKeyArnConfig, SecretManagerKeys } from './ApiConfigConstants';

export async function getToken() {
  const currentSession = await Auth.currentSession();
  return currentSession.getIdToken().getJwtToken();
}

export async function getCredentialsFromAWSService() {
  const COGNITO_LOGIN: any = {};
  const REGION = 'us-west-2';
  const CognitoIdentityPoolId = 'us-west-2:814f52b2-122c-445b-9889-e8553fa9184c';
  COGNITO_LOGIN[getCognitoDomain()] = await getToken();
  return fromCognitoIdentityPool({
    clientConfig: { region: REGION },
    identityPoolId: CognitoIdentityPoolId,
    logins: COGNITO_LOGIN
  });
}

function getCognitoDomain() {
  const REGION = 'us-west-2';
  const USER_POOL_ID = 'us-west-2_j9XeFakgq';
  return 'cognito-idp.' + REGION + '.amazonaws.com/' + USER_POOL_ID;
}

// Retrieves the API key from AWS Secrets Manager.
// Katal Logger isn't configured by the time this function is called.
export const retrieveAPIKey = async (): Promise<GetSecretValueResponse | undefined> => {
  const REGION = 'us-west-2';
  const stage = 'prod';
  const secretArn = SecretKeyArnConfig.find((config) => config.environment === stage)?.secretARN;

  if (secretArn) {
    const secretsManagerClientConfig: SecretsManagerClientConfig = {
      region: REGION,
      credentials: await getCredentialsFromAWSService()
    };
    const secretsManagerClient = new SecretsManagerClient(secretsManagerClientConfig);
    const command = new GetSecretValueCommand({ SecretId: secretArn });
    return await secretsManagerClient.send(command);
  }

  return undefined;
};

// Retrieves AppSync configuration, including the API key and endpoint, from AWS Secrets Manager.
export const getAppSyncConfig = (apiKeySecret: string | undefined) => {
  const authenticationType = 'AMAZON_COGNITO_USER_POOLS';
  const REGION = 'us-west-2';
  const stage = 'prod';

  try {
    const graphQlApiEndpoint = BackendGraphQLApiConfig.find((config) => config.environment === stage)?.finsuite_api;
    const graphQlApiKey = (apiKeySecret && JSON.parse(apiKeySecret)[SecretManagerKeys.API_KEY]) || '';
    return {
      Appsync: {
        aws_appsync_graphqlEndpoint: graphQlApiEndpoint,
        aws_appsync_region: REGION,
        aws_appsync_authenticationType: authenticationType,
        aws_appsync_apiKey: graphQlApiKey
      }
    };
  } catch (error) {
    console.error('Unable to retrieve API Key', error);
    throw error;
  }
};

// Retrieves API configuration, including endpoints and authentication details.
export const getApiConfig = () => {
  const stage = 'prod';

  const riskRemediatorApiEndpoint = BackendGraphQLApiConfig.find((config) => config.environment === stage)?.risk_remediator_api;
  const peopleApiEndpoint = BackendGraphQLApiConfig.find((config) => config.environment === stage)?.risk_remediator_api;

  const apiConfig = {
    endpoints: [
      {
        name: 'PEOPLE_API',
        endpoint: peopleApiEndpoint,
        region: 'us-east-1' // us-east-1 is the only supported region for PAPI.
      },
      {
        name: 'RISK_REMEDIATOR_API',
        endpoint: riskRemediatorApiEndpoint,
        custom_header: async () => {
          return {
            Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()
          };
        }
      }
    ]
  };

  return apiConfig;
};
